import './ReportFormSectionConfig.scss';
import { FieldPath } from '@form-ts/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { Project } from 'src/modules/common/types/Project';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { DateboxField } from 'src/modules/form/components/DateboxField';
import { FormError } from 'src/modules/form/types/FormError';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportFormFieldToggle } from 'src/modules/report/views/ReportFormFieldToggle';

type Props = {
  readonly field: FieldPath<ReportFormData, FormError, ReportFormData['cover']>;
  readonly project: Project;
};

export const ReportFormSectionConfigCover = ({ field, project }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <div className="bp-report-form-section-config">
      <div className="bp-report-form-section-config__header">
        <LeadText className="bp-report-form-section-config__header-title">
          <FormattedMessage id="report/form/cover/header"/>
        </LeadText>
      </div>

      <HorizontalDivider/>

      <div className="bp-report-form-section-config__content">
        <ReportFormFieldToggle field={field.at('showBuildingInfo')}>
          <FormattedMessage id="report/form/cover/showBuildingInfo"/>
        </ReportFormFieldToggle>

        <HorizontalDivider/>

        <div className="bp-report-form-section-config__empty-divider"/>

        <DateboxField
          size="md"
          field={field.at('creationDate')}
          label={intl.formatMessage({ id: 'report/form/cover/reportDate' })}
        />
      </div>

      <div className="bp-report-form-section-config__action">
        <CustomButton
          fullWidth={true}
          as={Link}
          to={`/project/${encodeURIComponent(project.id)}`}
          kind="tertiary"
          size="md"
        >
          <FormattedMessage id="report/form/cover/editLinkText"/>

          <div className="bp-report-form-section-config__button-icon">
            <ArrowRightIcon/>
          </div>
        </CustomButton>
      </div>
    </div>
  );
};
