import { SagaIterator } from 'redux-saga';
import { assetSaga } from 'src/modules/asset/sagas/assetSaga';
import { basedataSaga } from 'src/modules/basedata/sagas/basedataSaga';
import { CONFIG_FETCH } from 'src/modules/config/actions/ConfigActions';
import { configSaga } from 'src/modules/config/sagas/configSaga';
import { evaluationSaga } from 'src/modules/evaluation/sagas/evaluationSaga';
import { feedbackSaga } from 'src/modules/feedback/sagas/feedbackSaga';
import { hvacSaga } from 'src/modules/hvac/sagas/hvacSaga';
import { loaderSaga } from 'src/modules/loader/sagas/loaderSaga';
import { loginSaga } from 'src/modules/login/sagas/loginSaga';
import { modalStackSaga } from 'src/modules/modals/sagas/modalStackSaga';
import { passwordForgotSaga } from 'src/modules/password-forgot/sagas/passwordForgotSaga';
import { passwordResetSaga } from 'src/modules/password-reset/sagas/passwordResetSaga';
import { profileSaga } from 'src/modules/profile/sagas/profileSaga';
import { projectListSaga } from 'src/modules/project-list/sagas/projectListSaga';
import { projectNewSaga } from 'src/modules/project-new/sagas/projectNewSaga';
import { projectSyncSaga } from 'src/modules/project-sync/sagas/projectSyncSaga';
import { projectViewSaga } from 'src/modules/project-view/sagas/projectViewSaga';
import { reportSaga } from 'src/modules/report/sagas/reportSaga';
import { routingSaga } from 'src/modules/routing/sagas/routingSaga';
import { schemaSaga } from 'src/modules/schema/sagas/schemaSaga';
import { shareProjectSaga } from 'src/modules/sharing-projects/sagas/shareProjectSaga';
import { usersSaga } from 'src/modules/users/sagas/usersSaga';
import { fork, put, take } from 'typed-redux-saga';

export function* rootSaga(): SagaIterator {
  yield* fork(loaderSaga);
  yield* fork(configSaga);

  // prefetch configuration before starting the app
  yield* put(CONFIG_FETCH.request());
  yield* take(CONFIG_FETCH.isSuccess);

  yield* fork(routingSaga);
  yield* fork(loginSaga);
  yield* fork(passwordForgotSaga);
  yield* fork(passwordResetSaga);
  yield* fork(schemaSaga);
  yield* fork(hvacSaga);
  yield* fork(basedataSaga);
  yield* fork(projectNewSaga);
  yield* fork(projectSyncSaga);
  yield* fork(projectListSaga);
  yield* fork(projectViewSaga);
  yield* fork(evaluationSaga);
  yield* fork(reportSaga);
  yield* fork(assetSaga);
  yield* fork(modalStackSaga);
  yield* fork(feedbackSaga);
  yield* fork(profileSaga);
  yield* fork(usersSaga);
  yield* fork(shareProjectSaga);
}
