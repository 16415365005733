import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { getCurrentDate } from 'src/modules/config/selectors/getCurrentDate';
import { getReportFormData } from 'src/modules/report/selectors/getReportFormData';
import { ReportGeneratedCover } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedCover = createSelector([
  getProjectLazy,
  getReportFormData,
  getCurrentDate,
], (project, report, currentDate): ReportGeneratedCover => ({
  title: project.data.title,
  goals: project.data.goals,
  image: project.data.cover,
  building: project.data.building,
  email: project.owner?.email ?? '',
  author: project.data.participants.author,
  reportDate: report.cover.creationDate ?? currentDate,
}));
