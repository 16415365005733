import './UsersLayout.scss';
import { Breadcrumb } from '@carbon/react';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';

type Props = PropsWithChildren<{
  actions?: React.ReactNode;
  extension?: React.ReactNode;
  breadcrumbs: React.ReactNode;
  title: string;
}>;

export const UsersLayout = ({
  actions,
  children,
  extension,
  title,
  breadcrumbs,
}: Props): React.ReactElement => (
  <AppLayout className="bp-users-layout">
    <div className="bp-users-layout__header-wrapper">
      <div className="bp-users-layout__header-content">
        <header className="bp-users-layout__header">
          <Breadcrumb>
            {breadcrumbs}
          </Breadcrumb>

          <h1 className="bp-users-layout__header-title">{title}</h1>
          <div className="bp-users-layout__header-actions">{actions}</div>
        </header>

        <div className="bp-users-layout__header-extensions">
          {extension}
        </div>
      </div>
    </div>

    {children}
  </AppLayout>
);
